<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import axios from 'axios';
import DepartmentModal from '@/components/modals/department-modal.vue';
import api from '@/helpers/api-rest/api.js';

export default {
    page: {
        title: "Departamentos",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { 
        Layout, 
        PageHeader,
        DepartmentModal
    },
  data() {
    return {
      tableData: [],
      title: "Departamentos",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "id", sortable: false, label: "#" },
        { key: "name", sortable: false, label: "Nombre" },
        { key: "status", sortable: false, label: "" },
        { key: "action", tdClass:"custom-table-action",  sortable: false, label: "" }
      ],
      showDepartmentModal: false,
      editMode: false,
      departmentEdit: undefined,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    loadDataFromApi(){
      const apiEndpoint = `/api/v1/departments`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.tableData = response.data.data;
          console.log(this.tableData);
          this.tableData.forEach(department => {
            department.switchStatus = department.status === true;
          });

        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    success(department) {
      console.log(department);
      this.loadDataFromApi();
    },

    addDepartment() {
      this.departmentEdit = undefined;
    },

    editDepartment(department) {
        this.departmentEdit = department;
    },

    toggleStatus(department){
      console.log(department);
      const apiEndpoint = `/api/v1/departaments/${department.id}/change-status/?_method=PATCH`;
      api.post({url:apiEndpoint})
        .then(response => {
          console.log(response);
            // center.status = response.data.data.status;
            department.status = (response.data.data.status) ? 1 : 0;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },

    handleSwitchInput(department) {
      this.toggleStatus(department);
    },
  },
  mounted() {
    this.loadDataFromApi();
    this.totalRows = this.tableData.length;
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title">
      <template v-slot:header-button>
        <button v-b-modal.ModalDepartment @click="addDepartment()" class="btn btn-primary">
          <i class="fa fa-plus"></i> Añadir Departamento
        </button>
      </template>
    </PageHeader>
    <DepartmentModal :show-department-modal="showDepartmentModal" :department="departmentEdit" @success="success" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                ref="departmentTable"
              >
                <template v-slot:cell(status)="row">
                  <div>
                    <b-badge
                      pill
                      :variant="row.item.status === 1 ? 'success' : 'danger'"
                      class="ml-1"
                    >
                      {{ row.item.status === 1 ? 'Activo' : 'Inactivo' }}
                    </b-badge>
                  </div>
                </template>
                <template v-slot:cell(action)="row">
                  <div>
                    <a href="javascript:void(0);" class="mr-3 text-secondary" v-b-modal.ModalDepartment title="Edit" @click="editDepartment(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                    <b-form-checkbox
                        v-model="row.item.status"
                        switch class="mb-1"
                        value="1"
                        @change="handleSwitchInput(row.item)"
                    ></b-form-checkbox>
                  </div>
                </template>

                <!-- <template v-slot:cell(status)="row">
                  <switches
                    v-model="row.item.status"
                    type-bold="false"
                    color="primary"
                    class="ml-1 mb-0"
                    ref="switch"
                    @click.native="handleSwitchInput(row.item)"
                  />
                </template> -->

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>